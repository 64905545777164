.animals-carouselImage{
    object-fit: contain;
    min-height: 600px;
    
}
.animals-carousel{
    min-height: 600px;
    max-width: 1000px;
    margin: 0 auto;
}
.carousel-CaptionCustom{
    position: relative;
    color: #fff;
    bottom:40px;
    display: block;
    text-align: center;
}
.carousel-captionLarge{
    position: absolute;
    right: 15%;
    bottom: 4rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}
.leds-container{
    display: flex;
    flex-direction: column;
}
.spacer{
    height: 300px;
}
